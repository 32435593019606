<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="$t('TERMS.categories_nice')"
    :description="$t('PAGES.DESCRIPTIONS.nice_description')"
    has-back-button
    ></SectionHeader>
    <div class="main-container px-md-5 py-5">

      <h4>{{ $t('GENERAL.completed_fem') }}</h4>

      <!-- SKELETONS LOADING -->
      <b-row v-if="isLoading">
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
          <CategoryCardSkeleton />
        </b-col>
      </b-row>
      <!-- END SKELETONS LOADING -->

      <b-row v-else-if="!isLoading && completedCareers.length > 0">
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3"
        v-for="content in completedCareers"
        v-bind:key="content.id">
          <CategoryCard
            :img="getUrlImg(content.image)"
            :title="content.name"
            :text="content.description"
            :progress="calculateProgress(content.score, content.total_points)"
            class="category"
            :to="{ name: 'careerDetail', params: { id: content.id, name: content.title } }"
          >
          </CategoryCard>
        </b-col>
      </b-row>

      <p v-else-if="!isLoading">
        {{ $t('TERMS.NICE.no_completed') }}
      </p>

      <h4>{{ $t('GENERAL.pending') }}</h4>

      <!-- SKELETONS LOADING -->
      <b-row v-if="isLoading">
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
          <CategoryCardSkeleton />
        </b-col>
      </b-row>
      <!-- END SKELETONS LOADING -->

      <b-row v-else-if="!isLoading && incompleteCareers.length > 0">
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3"
        v-for="content in incompleteCareers"
        v-bind:key="content.id">
          <CategoryCard
            :img="getUrlImg(content.image)"
            :title="content.name"
            :text="content.description"
            :progress="calculateProgress(content.score, content.total_points)"
            class="category"
            :to="{ name: 'careerDetail', params: { id: content.id, name: content.title } }"
          >
          </CategoryCard>
        </b-col>
      </b-row>

      <p v-else-if="!isLoading">
        {{ $t('TERMS.NICE.all_completed') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import SectionHeader from '@/components/SectionHeader'

import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'

import mapper from '@/services/mixins/mapper.mixins'
import ProgressMixin from '@/services/mixins/progress.mixins'

export default {
  name: 'NiceByUser',
  mixins: [mapper, ProgressMixin],
  components: { CategoryCard, SectionHeader, CategoryCardSkeleton },
  data () {
    return { }
  },
  methods: {
    ...mapActions({
      getCareers: 'careers/getCareers'
    })
  },
  computed: {
    ...mapGetters({
      careers: 'careers/getCareers',
      isLoading: 'careers/isLoading'
    }),

    mergedCareers () {
      return this.careers.filter(i => this.completedCareers.indexOf(i.id) !== -1)
    },

    completedCareers () {
      return this.careers.filter(i => i.score === i.total_points)
    },

    incompleteCareers () {
      return this.careers.filter(i => i.score !== i.total_points)
    }
  },
  mounted () {
    this.getCareers()
  }
}
</script>
